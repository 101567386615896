import React, { useState } from 'react';
import { SHARECODE } from '@doda/common/src/constants/shareCode';
import { copyTextToClipboard } from '@doda/common/src/logics/shareContent/copyTextToClipboard';
import { ShareCodeDialogPresentaion } from './ShareCodeDialogPresentaion';

/**
 * Props
 */
interface Props {
  isOpen?: boolean;
  /* モーダルを閉じる処理 */
  onClose: () => void;
}

/**
 * ブログパーツ提供ダイアログ コンテナコンポーネント
 */
export const ShareCodeDialog: React.FC<Props> = ({ isOpen, onClose }) => {
  const [isCopied, setIsCopied] = useState(false);
  const clickCopyCodeButton = () => {
    copyTextToClipboard(SHARECODE);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  const closeShareCodeDialog = () => {
    setIsCopied(false);
    onClose();
  };

  return (
    <ShareCodeDialogPresentaion
      isOpen={isOpen}
      onClose={closeShareCodeDialog}
      isCopied={isCopied}
      onClickButton={clickCopyCodeButton}
    />
  );
};
