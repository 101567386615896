import React, { useEffect, useRef } from 'react';
import { ModalOverlayPresentaion } from './ModalOverlayPresentaion';

/**
 * ModalOverlayのProps
 */
interface Props {
  /** モーダルの表示状態（任意） */
  show?: boolean;
  /** オーバーレイがクリックされた時のハンドラー */
  onClickOverlay: () => void;
  /** モーダルの子要素 */
  children: React.ReactNode;
  /** 指定された場合、モーダル表示中もスクロールを固定しない */
  noScrollFix?: boolean;
}

/**
 * モーダルオーバーレイコンポーネント
 * @param {Props} props - コンポーネントのプロパティ
 * @returns {JSX.Element} ModalOverlayのJSX要素
 */
export const ModalOverlay: React.FC<Props> = ({
  show,
  onClickOverlay,
  children,
  noScrollFix,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const el = overlayRef.current;
    if (!el) return () => null;

    const clickModalOverlay = (e: MouseEvent) => {
      if (el?.contains(e.target as Node)) {
        onClickOverlay?.();
      }
    };

    document.addEventListener('click', clickModalOverlay);

    return () => {
      document.removeEventListener('click', clickModalOverlay);
    };
  }, [overlayRef, onClickOverlay]);

  useEffect(() => {
    if (noScrollFix) return;
    if (show) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [show, noScrollFix]);

  return (
    <ModalOverlayPresentaion show={show} overlayRef={overlayRef}>
      {children}
    </ModalOverlayPresentaion>
  );
};
