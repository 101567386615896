import React from 'react';
import { SHARECODE } from '@doda/common/src/constants/shareCode';
import { BasicModal, Box, Button } from '@doda/io-react/common';
import { ModalOverlay } from 'components/ModalOverlay';
import style from './index.module.scss';

/**
 * Props
 */
interface Props {
  isOpen?: boolean;
  /* モーダルを閉じる処理 */
  onClose: () => void;
  /* ボタン文言表示切り替えフラグ */
  isCopied: boolean;
  /* ボタン押下時の処理 */
  onClickButton: () => void;
}
/**
 * ブログパーツ提供ダイアログ プレゼンテーションコンポーネント
 */
export const ShareCodeDialogPresentaion: React.FC<Props> = ({
  isOpen,
  onClose,
  isCopied,
  onClickButton,
}) => {
  return (
    <ModalOverlay show={isOpen} onClickOverlay={onClose} noScrollFix>
      <BasicModal
        title="転職サイトdodaをシェア"
        width={450}
        height={216}
        show={isOpen}
        onClickClose={onClose}
        className={style.shareCodeDialogWhole}
      >
        <div className={style.shareCodeContainer}>
          <Box border="gray" className={style.codeArea} radius="4">
            {SHARECODE}
          </Box>
          <Button
            variant="outline_gray"
            className={style.shareCodeDialogButton}
            onClick={onClickButton}
          >
            {isCopied ? 'コピーしました' : 'コピー'}
          </Button>
        </div>
      </BasicModal>
    </ModalOverlay>
  );
};
