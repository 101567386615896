import { JobCountResponse } from '@doda/api-client/aspida/@types';
import { AppQueryParams } from '../../../constants/queryParams';
import { getJobCount } from '../../../services/getJobCountAPI';
import { opToJobCountResponsKV } from '../../optionQueryUtil';
import { nonNullable } from '../../typeGuard';
import { createJobCountRequestParams } from '../../../logics/createJobCountRequestParams';

/**
 * 求人件数一括取得（ファセット検索）
 * @param query
 * @returns
 */
export const getSearchFacetCount = async (query: AppQueryParams) => {
  const params = createJobCountRequestParams(query);
  const result = await getJobCount(params);
  return result;
};

/**
 * opとJobCountResponseのKey/Valueを紐づけてidとcountを返す
 * @param master
 * @param searchFacetCount
 * @returns
 */
export const convOpSearchFacetCount = (
  master: string[][],
  searchFacetCount: JobCountResponse
) => {
  return master
    .map(([id]) => {
      const requestQuery = opToJobCountResponsKV(id);
      if (!requestQuery) return undefined;

      const facetName = requestQuery.key;
      const count = searchFacetCount[facetName]?.find(
        (item) => item.id === requestQuery.value
      )?.count;

      if (!count) return undefined;

      return { id, count };
    })
    .filter(nonNullable);
};

/**
 * ocとJobCountResponseのKey/Valueを紐づけてidとcountを返す（絞り込みボタン用）
 * @param master 職種マスタ情報
 * @param searchFacetCount ファセット件数
 * @returns 職種IDとそのIDの求人件数
 */
export const convOcSearchFacetCountForSeoButton = (
  master: string[][],
  searchFacetCount: JobCountResponse
) => {
  return master
    .map(([id]) => {
      if (id === '11' || id === '01' || id === '02') {
        const facetName = 'searchJobTypeLList';
        const count = searchFacetCount[facetName]?.find(
          (item) => item.id === id
        )?.count;
        if (!count) return undefined;
        return { id, count };
      }

      if (id === '1113' || id === '0202' || id === '0927' || id === '0210') {
        const facetName = 'searchJobTypeMList';
        const count = searchFacetCount[facetName]?.find(
          (item) => item.id === id
        )?.count;
        if (!count) return undefined;
        return { id, count };
      }

      return undefined;
    })
    .filter(nonNullable);
};
