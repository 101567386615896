import { ShareCodeDialog } from '../ShareCodeDialog';
import style from './index.module.scss';
/** ShareContentコンポーネントのプロパティ */
interface Props {
  /** リンクリストのアイテム */
  linkList: {
    /** リンクのURL */
    href: string;
    /** 画像のURL */
    image: string;
    /** 画像のaltテキスト */
    alt: string;
    /** シェアアイコンかどうか */
    isShareCode?: boolean;
  }[];
  onClick: () => void;
  onClose: () => void;
  isDialogOpen: boolean;
}

/**
 * ShareContentコンポーネント
 *
 * @param {Props} props - コンポーネントのプロパティ
 * @param {Object[]} props.linkList - リンクリストのアイテム
 * @param {string} props.linkList.href - リンクのURL
 * @param {string} props.linkList.image - 画像のURL
 * @param {string} props.linkList.alt - 画像のaltテキスト
 * @returns {JSX.Element} - ShareContentコンポーネント
 */
export const ShareContentPresentaion: React.FC<Props> = ({
  linkList,
  onClick,
  onClose,
  isDialogOpen,
}) => {
  return (
    <>
      <ul className={style.shareContent}>
        {linkList.map((listItem) => {
          if (listItem.isShareCode) {
            return (
              <li key={listItem.image}>
                <button type="button" onClick={onClick}>
                  <img src={listItem.image} alt={listItem.alt} />
                </button>
              </li>
            );
          }
          return (
            <li key={listItem.image}>
              <a href={listItem.href} target="_blank" rel="noreferrer">
                <img src={listItem.image} alt={listItem.alt} />
              </a>
            </li>
          );
        })}
      </ul>
      <ShareCodeDialog onClose={onClose} isOpen={isDialogOpen} />
    </>
  );
};
