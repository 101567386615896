import type { Modify } from '../utils/typeUtil';

// アプリで使用されている全クエリ。使用されているクエリが他にもあれが追記する
export const QUERY_PARAMS = {
  /** seoで使われているトップ遷移判定 */
  fromSpTop: 'fromSpTop',
  /** 更新日 */
  wserdt: 'wserdt',
  /** アクション区分 */
  action: 'action',

  /** キーワード(～含む) */
  k: 'k',

  /** 勤務地地域 */
  ar: 'ar',

  /** 勤務地都道府県 */
  pr: 'pr',

  /** 職種 */
  oc: 'oc',

  /** 希望条件職種コード */
  matchJobOc: 'matchJobOc',

  /** 業種 */
  ind: 'ind',

  /** 検索結果数 */
  so: 'so',

  /** コンテンツ区分 */
  cd: 'cd',

  /** キーワード(～含まない) */
  ni: 'ni',

  /** 検索指定開始位置 */
  ss: 'ss',

  /** ソートパターン */
  s: 's',

  /** その他 */
  op: 'op',

  /** キーワード(DODAトップから) */
  kw: 'kw',

  /** 設立 */
  es: 'es',

  /** 検索ボックスだし分けフラグ */
  preBtn: 'preBtn',

  /** 検索ボックスだし分けフラグ(保存用) */
  maintePreBtn: 'maintePreBtn',

  /** 希望年収 */
  ha: 'ha',

  /** 表示形式 */
  pic: 'pic',

  /** 表示方式 */
  ds: 'ds',

  /** 求人種別 */
  tp: 'tp',

  /** 従業員数 */
  ne: 'ne',

  /** オーダーNo */
  o: 'o',

  /** 案件ID */
  jid: 'jid',

  /** 検索結果一覧の求人種類 */
  btn: 'btn',

  /** 上場区分 */
  mkt: 'mkt',

  /** 募集年齢 */
  a: 'a',

  /** メルマガ（経由／セクション）(サイトカタリスト専用) */
  m: 'm',

  /** ボタンフラグ */
  bf: 'bf',

  /** 個人顧客保存検索条件明細No */
  mpsc_dtno: 'mpsc_dtno',

  /** サイト区分 */
  mpsc_sid: 'mpsc_sid',

  /** 検索条件名 */
  textarea: 'textarea',

  /** 取得した総件数 */
  totalCount: 'totalCount',

  /** 1ページ内の表示件数 */
  pageCount: 'pageCount',

  /** 現在表示中のページ */
  pageNumber: 'pageNumber',

  /** 取得件数の上限 */
  limitCount: 'limitCount',

  /** 現在表示している位置 */
  offset: 'offset',

  /** 法人窓口ID */
  corpReceptionId: 'corpReceptionId',

  /** バナーID */
  bnid: 'bnid',

  /** セミナー開催月検索条件 */
  month: 'month',

  /** セミナー表示条件 */
  disp: 'disp',

  /** 原稿ID */
  wmuid: 'wmuid',

  /** 応募先ID */
  ap_id: 'ap_id',

  /** スカウトID */
  scoutlist_id: 'scoutlist_id',

  /** メッセージID */
  message_id: 'message_id',

  /** 特集枠ID */
  fid: 'fid',

  /** キーワード含む検索範囲フラグ */
  kwc: 'kwc',

  /** キーワード含まない検索範囲フラグ */
  niwc: 'niwc',

  /** 特集用キーワード(～含む) */
  feak: 'feak',

  /** 特集用キーワード(～含まない) */
  feani: 'feani',

  /** 特集用キーワード(～含む)検索範囲フラグ */
  feakwc: 'feakwc',

  /** 特集用キーワード(～含まない)検索範囲フラグ */
  feaniwc: 'feaniwc',

  /** 遷移状態 */
  from_global_navi: 'from_global_navi',

  /** 指定ページ */
  page: 'page',

  /** 初回表示時保持クエリストリング */
  initQueryString: 'initQueryString',

  /** OSスカウト区分 */
  osscfl: 'osscfl',

  /** チェックボックス非選択項目 */
  unchecked: 'unchecked',

  /** 現画面URL */
  currentUrl: 'currentUrl',

  /** 前のページに戻るの文言 */
  returnWord: 'returnWord',

  /** 遷移元のページID */
  pageId: 'pageId',

  /** 市区町村団体コード */
  ci: 'ci',

  /** 政令指定都市団体コード */
  wa: 'wa',

  /** 路線コード */
  rt: 'rt',

  /** 駅コード */
  st: 'st',

  /** 資格コード */
  qc: 'qc',

  /** 遷移元区分 */
  from: 'from',

  /** 左パネルだし分けフラグ */
  leftPanelType: 'leftPanelType',

  /** 検索結果一覧レコメンド化 計測用パラメータ */
  rlist: 'rlist',

  /** SPトップからの遷移によるuserclkパラメータ付与 */
  usrclk: 'usrclk',
  /** SP一覧からの遷移によるuserclkパラメータ付与 */
  usrclk_searchList: 'usrclk_searchList',

  /** 近隣エリア新規一覧表示用フラグ */
  nearArea: 'nearArea',

  /** 近隣エリア新規一覧表示用元エリア */
  baseArea: 'baseArea',

  /** 都道府県のみ絞り込みフラグ */
  pf: 'pf',

  /** 該当遷移元またはクローラーか判定フラグ */
  refererOrBotFlg: 'refererOrBotFlg',

  /** 求人詳細用のタブ情報 */
  tab: 'tab',
  /** 求人詳細用のメッセージ情報 */
  msg: 'msg',
  /** 求人特集名 */
  featuredJobNm: 'featuredJobNm',
  /** セミナーグループID */
  seminargroup_id: 'seminargroup_id',
  /** スカウトリストID */
  wlstid: 'wlstid',
  /*  */
  cr: 'cr',
  /*  */
  rm: 'rm',
  /*  */
  ocf: 'ocf',
  /*  */
  cc: 'cc',
  /*  */
  x: 'x',
  /*  */
  y: 'y',
  /* 記録用スカウトリストID */
  record_scoutlist_id: 'record_scoutlist_id',
  /* 記録用メッセージID */
  record_message_id: 'record_message_id',
  /* レコメンドID */
  recommendID: 'recommendID',
  /* エントリー経路情報 */
  entry_route: 'entry_route',
  /* お知らせ配信遷移経由区分 */
  ndrs: 'ndrs',
  /* 候補者ID */
  wsfid: 'wsfid',

  /* バナーNOT */
  bnnt: 'bnnt',

  /* メールマガジン */
  cid: 'cid',

  /* 効果測定用遷移元区分 */
  fm: 'fm',

  /* PVカウント判定パラメータ */
  inp: 'inp',

  /* ページ関連がクリックされた(勤務地の都道府県の設定が少ない順の並び替え判定で使用) */
  prsrt: 'prsrt',

  /* 求人情報 */
  info: 'info',

  /* 応募状況 */
  entryStatus: 'entryStatus',

  /* テスト対象 */
  target: 'target',

  /* カウンセリング予約対象者かどうかを判定するフラグ */
  counselingFlg: 'counselingFlg',

  /** 企業メディアパラメータ */
  KGMmeasures: 'KGMmeasures',

  /** タブ */
  tabContent: 'tabContent',
};

export type QueryParams = Partial<typeof QUERY_PARAMS>;

/**
 * QueryOtherのParams
 */
export interface QueryOtherParams {
  slashSortflg?: boolean;
}

export type Item = string[];

export type AppQueryParams = Modify<
  QueryParams,
  {
    jid?: Item;
    ar?: Item;
    pr?: Item;
    ci?: Item;
    wa?: Item;
    rt?: Item;
    st?: Item;
    oc?: Item;
    ind?: Item;
    op?: Item;
    es?: Item;
    ne?: Item;
    ha?: Item;
  }
>;

export type FeaturedJobListQueryParams = Partial<
  typeof JOB_SEARCH_LIST_REQUEST_QUERY_PARAM_FOR_CHECK
>;

export const JOB_SEARCH_LIST_REQUEST_QUERY_PARAM = {
  /** 地域コード */
  areaIdList: 'ar',
  /** 都道府県コード */
  prefectureIdList: 'pr',
  /** 市区町村団体コード */
  cityIdList: 'ci',
  /** 政令指定都市団体コード */
  wardIdList: 'wa',
  /** 路線コード */
  routeIdList: 'rt',
  /** 駅コード */
  stationIdList: 'st',
  /** 職種コード */
  searchJobType: 'oc', // 細分化が必要
  /** 業種コード */
  industry: 'ind', // 細分化が必要
  /** キーワード（～含む） */
  includedKeywordList: 'k',
  /** キーワード（～含む）検索範囲 */
  includedKeywordCategory: 'kwc',
  /** キーワード（～含まない） */
  excludedKeywordList: 'ni',
  /** キーワード（～含まない）検索範囲 */
  excludedKeywordCategory: 'niwc',
  /** オプション */
  optionList: 'op',
  /** 設立 */
  establishList: 'es',
  /** 従業員数 */
  employNumList: 'ne',
  /** 最高希望年収*/
  upperLimitIncome: 'ha', // 細分化が必要
  /** 最低希望年収 */
  lowerLimitIncome: 'ha', // 細分化が必要
  /** 都道府県コード（除外） */
  excludedPrefectureIdList: 'prRmv',
  /** 市区町村団体コード（除外） */
  excludedCityIdList: 'ciRmv',
  /** 政令指定都市団体コード（除外） */
  excludedWardIdList: 'waRmv',
  /** 駅コード（除外） */
  excludedStationIdList: 'stRmv',
  /** 求人種別 */
  jobOfferKind: 'tp',
  /** 並べ変え順 */
  sort: 's',
  /** 表示方法 */
  display: 'ds',
  /** 1ページ当たりの表示件数 */
  limit: 'so',
  /** 検索開始位置 */
  offset: 'ss',
  /** 都道府県のみ絞り込みフラグ */
  isSearchByPrefecture: 'pf',
};

export const JOB_SEARCH_LIST_REQUEST_QUERY_PARAM_FOR_CHECK = {
  /** 地域コード */
  ar: 'ar',
  /** 都道府県コード */
  pr: 'pr',
  /** 市区町村団体コード */
  ci: 'ci',
  /** 政令指定都市団体コード */
  wa: 'wa',
  /** 路線コード */
  rt: 'rt',
  /** 駅コード */
  st: 'st',
  /** 職種コード */
  oc: 'oc', // 細分化が必要
  /** 業種コード */
  ind: 'ind', // 細分化が必要
  /** キーワード（～含む） */
  feak: 'feak',
  /** キーワード（～含む）検索範囲 */
  feakwc: 'feakwc',
  /** キーワード（～含まない） */
  feani: 'feani',
  /** キーワード（～含まない）検索範囲 */
  feaniwc: 'feaniwc',
  /** オプション */
  op: 'op',
  /** 設立 */
  es: 'es',
  /** 従業員数 */
  ne: 'ne',
  /** 最高希望年収*/
  ha: 'ha', // 細分化が必要
  /** 都道府県コード（除外） */
  prRmv: 'prRmv',
  /** 市区町村団体コード（除外） */
  ciRmv: 'ciRmv',
  /** 政令指定都市団体コード（除外） */
  waRmv: 'waRmv',
  /** 駅コード（除外） */
  stRmv: 'stRmv',
  /** 求人種別 */
  tp: 'tp',
  /** 並べ変え順 */
  s: 's',
  /** 表示方法 */
  ds: 'ds',
  /** 1ページ当たりの表示件数 */
  so: 'so',
  /** 検索開始位置 */
  ss: 'ss',
  /** 都道府県のみ絞り込みフラグ */
  pf: 'pf',
};
