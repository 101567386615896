import { SortRequestCode } from '@doda/api-client/aspida/@types';

/**
 * OPTION
 */
export const IS_NEW_ARRIVAL = '1';
export const IS_NEAR_DEDLINE = '20';
export const EMPLOYMENT_STATUS = ['17', '18', ['19', '57', '58', '59', '64']];
export const HOLIDAY_AND_WAY_OF_WORKING = [
  '69',
  '5',
  '72',
  '73',
  '74',
  '60',
  '6',
  '75',
];
export const REQUIREMENTS = [
  '70',
  '71',
  '24',
  '27',
  '3',
  '67',
  '76',
  '48',
  '86',
];
export const WORKPLACE_ENVIRONMENT = ['47', '4', '77', '33', '55', '32'];
export const WELFARE_BENEFITS = [
  '56',
  '44',
  '65',
  '45',
  '46',
  '79',
  '80',
  '81',
];
export const LANGUAGE_SKILLS = ['21', '49'];
export const POSITIONS = ['78', '8'];
export const EMPLOYEE_AVERAGE_AGE = ['82', '83', '84', '85'];

/**
 * OPTION以外
 */
export const ACCETABLE_INCLUDE_KEYWORD_CATEGORY_VALUES = [
  '1',
  '2',
  '3',
  '4',
  '9',
];
export const ACCETABLE_EXCLUDE_KEYWORD_CATEGORY_VALUES = [
  '1',
  '2',
  '3',
  '4',
  '9',
];
export const ACCETABLE_ESTABLISH_VALUES = ['1', '2', '3', '4'];
export const ACCETABLE_EMPLOY_NUM_VALUES = ['1', '2', '3', '4'];
export const ACCETABLE_LOWER_LIMIT_INCOME_VALUES = [
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
  '60',
  '65',
  '70',
  '80',
  '90',
  '100',
];
export const ACCETABLE_UPPER_LIMIT_INCOME_VALUES = [
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
  '60',
  '65',
  '70',
  '80',
  '90',
  '100',
];
export const ACCETABLE_JOB_OFFER_KIND_VALUES = ['0', '2'];
export const ACCETABLE_SORT_VALUES: Record<
  '1' | '2' | '3' | '4',
  SortRequestCode
> = {
  '1': '1',
  '2': '2',
  '3': '5',
  '4': '6',
};
