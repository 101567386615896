import { useDisclosure } from '@doda/common/src/hooks/useDisclosure';
import { ShareContentPresentaion } from './ShareContentPresentaion';
/** ShareContentコンポーネントのプロパティ */
interface Props {
  /** リンクリストのアイテム */
  linkList: {
    /** リンクのURL */
    href: string;
    /** 画像のURL */
    image: string;
    /** 画像のaltテキスト */
    alt: string;
    /** シェアアイコンかどうか */
    isShareCode?: boolean;
  }[];
}

/**
 * ShareContentコンポーネント
 *
 * @param {Props} props - コンポーネントのプロパティ
 * @param {Object[]} props.linkList - リンクリストのアイテム
 * @param {string} props.linkList.href - リンクのURL
 * @param {string} props.linkList.image - 画像のURL
 * @param {string} props.linkList.alt - 画像のaltテキスト
 * @returns {JSX.Element} - ShareContentコンポーネント
 */
export const ShareContent: React.FC<Props> = ({ linkList }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onClickShareCode = () => {
    onOpen();
  };
  return (
    <ShareContentPresentaion
      linkList={linkList}
      onClick={onClickShareCode}
      onClose={onClose}
      isDialogOpen={isOpen}
    />
  );
};
