import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

/**
 * ModalOverlayのProps
 */
interface Props {
  /** モーダルの表示状態（任意） */
  show?: boolean;
  /** モーダルの子要素 */
  children: React.ReactNode;
  overlayRef: React.RefObject<HTMLDivElement>;
}

/**
 * モーダルオーバーレイコンポーネント
 * @param {Props} props - コンポーネントのプロパティ
 * @returns {JSX.Element} ModalOverlayのJSX要素
 */
export const ModalOverlayPresentaion: React.FC<Props> = ({
  show,
  children,
  overlayRef,
}) => {
  return (
    <div
      className={classNames(styles.modalOverlay, {
        [styles['modalOverlay--show']]: show,
      })}
    >
      <div className={styles.modalOverlay__background} ref={overlayRef} />
      <div className={styles.modalOverlay__contents}>{children}</div>
    </div>
  );
};
